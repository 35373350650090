import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

class IndexPage extends React.Component {
    render() {
        return (
            <Layout active="Gallery" index={true}>
                <SEO title="Home" />
                <div className="showcase-wrapper">
                    <div className="showcase">
                        <Link to="/corporate">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[3]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Corporate</div>
                            </div>
                        </Link>
                        <Link to="/editorial">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[4]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Editorial</div>
                            </div>
                        </Link>
                        <Link to="/beauty">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[0]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Beauty</div>
                            </div>
                        </Link>
                        <Link to="/fashion">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[1]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Fashion</div>
                            </div>
                        </Link>
                        <Link to="/lifestyle">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[2]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Lifestyle</div>
                            </div>
                        </Link>
                        <Link to="/spaces">
                            <div className="item">
                                <div className="section">
                                    <Img
                                        fluid={{
                                            ...this.props.data.images.edges[6]
                                                .node.childImageSharp.fluid,
                                            sizes: `(max-width: 1024px) calc(50vw - 47.61px), 20vw`
                                        }}
                                    />
                                </div>
                                <div className="section-text">Spaces</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
    query {
        images: allFile(
            sort: { fields: name }
            filter: { relativeDirectory: { eq: "sections" } }
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
